import { useEffect, useState } from 'react'
import { Html5Qrcode } from 'html5-qrcode'

export const useGetVideoPermission = () => {
  const [videoPermission, setVideoPermission] = useState(false)
  const [isVideoPermissionLoading, setIsVideoPermissionLoading] = useState(true)

  useEffect(() => {
    Html5Qrcode.getCameras()
      .then((devices) => {
        setVideoPermission(devices && devices.length > 0)
      })
      .catch((error) => {
        console.error('failed to getCameras', error)
        setVideoPermission(false)
      })
      .finally(() => {
        setIsVideoPermissionLoading(false)
      })
  }, [])

  return { videoPermission, isVideoPermissionLoading }
}
