import { Select } from 'chakra-react-select'

import { FormControlWrapper } from '../FormControlWrapper'

import { useBareSelectField } from './useSelectField'
import { makeChakraStyles } from './styles'
import { useCustomComponentsFactory } from './useCustomComponentsFactory'
import type { SelectProps } from './types'

export const BareSelect = ({
  name,
  label,
  placeholder,
  caption,
  options,
  size,
  leftIconMemoized,
  isInvalid,
  isIconOnlyValue,
  componentsMemoized: componentsFromProps,
  ...props
}: SelectProps) => {
  const { isDisabled } = props
  const bareSelectProps = useBareSelectField({ name, placeholder })

  const makeCustomComponents = useCustomComponentsFactory()
  const components = makeCustomComponents({
    leftIcon: leftIconMemoized,
    isIconOnlyValue,
  })

  const styles = makeChakraStyles({
    size,
    isIconOnlyValue,
    isInvalid,
  })

  return (
    <FormControlWrapper
      name={name}
      label={label}
      caption={caption}
      isDisabled={isDisabled}
    >
      <Select
        isSearchable={false}
        options={options}
        chakraStyles={styles}
        components={componentsFromProps ?? components}
        {...props}
        {...bareSelectProps}
      />
    </FormControlWrapper>
  )
}
