import { useLocation, useRouteMatch } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { getOciSessionId } from 'utils/getOciSessionId'
import { PATHS } from 'consts/paths'

import {
  useIsAuthorized,
  useLogout,
  useOciSession,
} from 'app/containers/common/Auth'
import { setOciSessionId } from 'app/containers/common/Auth/authSlice'

export const useSetOciSessionIdEffect = () => {
  const dispatch = useDispatch()
  const { ociSessionId } = useOciSession()
  const pathOciSessionId = getOciSessionId()
  const shouldInitOciFlow =
    (pathOciSessionId && !ociSessionId) ||
    (pathOciSessionId && pathOciSessionId !== ociSessionId)

  useEffect(() => {
    if (shouldInitOciFlow) {
      dispatch(setOciSessionId(pathOciSessionId))
    }
  }, [dispatch, ociSessionId, pathOciSessionId, shouldInitOciFlow])

  return { shouldInitOciFlow }
}

export const useLogoutAuthorizedUserEffect = () => {
  const { isAuthorized } = useIsAuthorized()
  const { mutate: logout } = useLogout()

  useEffect(() => {
    if (isAuthorized) {
      logout()
    }
  }, [isAuthorized, logout])

  return { isAuthorized }
}

export const useDataForClearingOciRootPath = ():
  | { shouldRedirect: true; redirectPath: string }
  | { shouldRedirect: false; redirectPath: null } => {
  const hasOciSessionRootPath = !!useRouteMatch({
    path: PATHS.customer.ociSessionRoot,
    exact: false,
    strict: true,
  })

  const location = useLocation()

  // INFO: We just need everything except the oci scope and session id and
  // using destructuring with every variable declared seems like the most readable solution
  const [, _scope, _ociSessionId, ...pathToRedirectParts] =
    location.pathname.split('/')

  const shouldRedirect = hasOciSessionRootPath && pathToRedirectParts.length > 0

  if (shouldRedirect) {
    return {
      shouldRedirect,
      redirectPath: new URL(
        pathToRedirectParts.join('/'),
        window.location.origin,
      ).pathname,
    }
  }

  return {
    shouldRedirect,
    redirectPath: null,
  }
}
