import type { StackProps } from '@chakra-ui/react'
import { Stack, HStack, Box } from '@chakra-ui/react'
import type { ReactNode } from 'react'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { Typography } from '../Typography'

export type SectionHeaderProps = StackProps & {
  header?: ReactNode
  headerTestId?: string
  subHeader?: ReactNode
  description?: ReactNode
  rightSideRendered?: ReactNode
  size?: 'md' | 'sm'
}

export const SectionHeader = ({
  header,
  headerTestId,
  subHeader,
  description,
  rightSideRendered,
  align,
  size = 'md',
  ...rest
}: SectionHeaderProps) => {
  const { isMobile } = useBreakpointScreenSize()

  const sizeConfig = {
    sm: {
      headerTextStyle: 'headerH4',
      descriptionColor: 'contentB',
    },
    md: {
      headerTextStyle: 'headerH3',
      descriptionColor: 'contentA',
    },
  } as const

  const sizeStyles = sizeConfig[size]

  return (
    <Stack w="100%" spacing="0" align={align} {...rest}>
      <HStack w="100%" justify={align ?? 'space-between'}>
        {header && (
          <Typography
            maxW="100%"
            textStyle={sizeStyles.headerTextStyle}
            //TODO: Remove margins and set min height on SectionHeader https://simplesystem.atlassian.net/browse/S2NG-8137
            my={isMobile ? '0px' : '8px'}
            data-testid={headerTestId}
          >
            {header}
          </Typography>
        )}

        {rightSideRendered && <Box flexShrink={0}>{rightSideRendered}</Box>}
      </HStack>

      {(subHeader || description) && (
        <Stack w="100%" spacing="16px" align={align}>
          {subHeader && (
            <Typography maxW="100%" textStyle="inputNormal" color="contentB">
              {subHeader}
            </Typography>
          )}

          {description && (
            <Typography
              maxW="100%"
              textStyle="textParagraph"
              color={sizeStyles.descriptionColor}
            >
              {description}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  )
}
