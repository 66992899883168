export const {
  API_URL,
  GTM_ID,
  GTM_AUTH,
  GTM_PREVIEW,
  SENTRY_ENV,
  SENTRY_DSN,
  FEATURE_FLAGS: {
    EXTENDED_TESTING_OPTIONS,
    MOCK_SERVICE_WORKER_ENABLED,
    SHOW_PO_EDITOR_KEYS_LANGUAGE,
    EXTERNAL_SEARCH_ENDPOINT_ENABLED,
    SELECTIVE_CHECKOUT_ENABLED,
  },
  LINKS,
  APPROVAL_FLOWS_GUIDELINES_LINK,
  ARTICLE_INDIVIDUALISATION_GUIDELINES_LINK,
  CUSTOMER_OCI_INTEGRATION_INFO_LINK, // Remove after prod release https://simplesystem.atlassian.net/browse/S2NG-6814
  ASCII_SAMPLE_FILE_LINK,
  FTP_DATA_UPLOAD_GUIDELINES_LINK,
  OPENTRANS_10_SAMPLE_FILE_LINK,
  OPENTRANS_21_SAMPLE_FILE_LINK,
  REST_API_SAMPLE_FILE_LINK,
  BMD_ERP_SAMPLE_FILE_LINK,
  S2XML_SAMPLE_FILE_LINK,
  VENDOR_MANAGEMENT_EMAIL,
  COMPANY_COUNTRY_CONFIG,
  FAQ_LINK,
  VENDOR_FAQ_LINK,
  REST_API_REQUEST_ORDER_LIST_URL,
  REST_API_REQUEST_ORDER_DETAILS_URL,
  GARANT_TOOL24_CONFIGURATION_MANUAL_URL,
  HOLEX_24SEVEN_CONFIGURATION_MANUAL_URL,
  SEND_VENDING_MACHINE_ORDER_TO_EMAIL,
  EXTERNAL_USER_ARTICLES_SEARCH_API_URL,
  EXTERNAL_USER_ARTICLE_DETAILS_API_URL,
  EXTERNAL_USER_API_DOCS,
  EXTERNAL_USER_GUIDELINES_LINK,
  EXTRA_SEARCH_PROFILES,
} = window.ynd_env

export const IS_TEST = process.env.NODE_ENV === 'test'
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development'
export const IS_PRODUCTION = process.env.NODE_ENV === 'production'
