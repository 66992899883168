import { useTranslation } from 'react-i18next'
import type { ReactNode } from 'react'

import { translations } from 'locales/translations'

import type { ButtonProps } from 'app/components/Buttons/Button'
import { Button } from 'app/components/Buttons/Button'
import { PrintIcon } from 'app/components/icons'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { usePrint } from './usePrint'

const messages = translations.components.printButton

export type PrintButtonProps = (
  | {
      variant?: ButtonProps['variant']
      renderCustomPrintButton?: never
      isDisabled?: ButtonProps['isDisabled']
    }
  | {
      renderCustomPrintButton: (handlePrint: () => void) => ReactNode
      variant?: never
      isDisabled?: never
    }
) & {
  documentTitle?: string
  size?: ButtonProps['size']
}

export const PrintButton = ({
  renderCustomPrintButton,
  variant,
  documentTitle,
  isDisabled,
  size,
}: PrintButtonProps) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()
  const { handlePrint } = usePrint({ documentTitle })

  if (isMobile) return null

  if (renderCustomPrintButton)
    return <>{renderCustomPrintButton(handlePrint)}</>

  return (
    <Button
      variant={variant ?? 'tertiary'}
      onClick={handlePrint}
      leftIcon={<PrintIcon />}
      isDisabled={isDisabled}
      size={size}
    >
      {t(messages.label)}
    </Button>
  )
}
