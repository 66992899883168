import type { Theme } from 'theme'

import type { ButtonProps } from '../types'

export const getButtonVariantRelatedStyles = ({
  color,
  variant,
  isWithoutBorder,
  theme: { colors, borders, shadows },
}: Pick<ButtonProps, 'variant' | 'color' | 'isWithoutBorder'> & {
  theme: Theme
}) => {
  const { border, borderOnHover } = {
    border: isWithoutBorder ? undefined : borders.primary,
    borderOnHover: isWithoutBorder ? undefined : borders.darker,
  }

  switch (variant) {
    case 'primary':
      return {
        color: color ?? colors.white,
        background: colors.blue_500,
        ':disabled, :hover:disabled': {
          background: colors.backgroundC,
          opacity: 1,
          '&[data-ischecked="true"]': {
            color: colors.white,
            background: colors.contentB,
          },
        },
        ':hover:not([disabled]):not(:active)': {
          background: colors.blue_400,
        },
        ':active': {
          background: colors.blue_600,
        },
      }
    case 'secondary':
      return {
        background: colors.backgroundC,
        ':disabled, :hover:disabled': {
          background: colors.backgroundC,
        },
        ':hover:not([disabled]):not(:active)': {
          background: colors.backgroundD,
        },
        ':active': {
          background: colors.backgroundE,
        },
      }
    case 'tertiary':
      return {
        border,
        background: 'transparent',
        ':disabled, :hover:disabled': {
          background: 'transparent',
          color: colors.contentOff,
          opacity: 1,
        },
        ':hover:not([disabled]):not(:active)': {
          border: borderOnHover,
          background: 'transparent',
        },
        ':active': {
          background: colors.backgroundC,
        },
      }
    case 'red':
      return {
        color: color ?? colors.white,
        background: colors.red_500,
        ':disabled, :hover:disabled': {
          background: colors.backgroundC,
        },
        ':hover:not([disabled]):not(:active)': {
          background: colors.red_400,
        },
        ':active': {
          background: colors.red_600,
        },
      }
    case 'error':
      return {
        background: colors.red_100,
        ':disabled, :hover:disabled': {
          background: colors.backgroundC,
        },
      }
    case 'white':
      return {
        border,
        background: colors.white,
        ':disabled, :hover:disabled': {
          background: colors.white,
        },
        ':hover:not([disabled]):not(:active)': {
          border: borderOnHover,
          background: colors.backgroundD,
        },
        // INFO:[data-active] is applied in `PopoverButton` when popover is opened
        '&[data-active],&:active': {
          background: colors.backgroundD,
        },
      }
    case 'success':
      return {
        color: color ?? colors.white,
        background: colors.green_500,
        ':disabled, :hover:disabled': {
          background: colors.green_500,
        },
        ':hover:not([disabled]):not(:active)': {
          background: colors.green_500,
        },
        ':active': {
          background: colors.green_500,
        },
      }
    case 'fastAction':
      return {
        background: colors.white,
        boxShadow: shadows.tag,
        borderRadius: '100vh',
        ':hover:not([disabled]):not(:active)': {
          boxShadow: shadows.primary,
        },
      }
  }
}
