import {
  Box,
  CircularProgress,
  type CircularProgressProps,
  type BoxProps,
} from '@chakra-ui/react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { useTheme } from 'theme'

import { Button, type ButtonProps } from 'app/components/Buttons/Button'

import { getQrBoxDistanceFromTop } from '../useCodeScan'

// INFO: The Underneath circle element from chakra is not 1 to 1 aligned with the provided size, so we need the parent element to be bigger to match the designs
export const PROGRESS_ELEMENT_SIZE_RAW = 67
export const BUTTON_SIZE_RAW = 40
export const PROGRESS_ELEMENT_MARGIN_TOP_RAW = 60

export const CircleButton = (props: ButtonProps) => (
  <Button
    style={{
      width: `${BUTTON_SIZE_RAW}px`,
      height: `${BUTTON_SIZE_RAW}px`,
      borderRadius: '50%',
      padding: 0,
    }}
    {...props}
  />
)

export const StyledCircularProgress = (props: CircularProgressProps) => {
  const { colors } = useTheme()

  return (
    <CircularProgress
      color={colors.blue_500}
      size={`${PROGRESS_ELEMENT_SIZE_RAW}px`}
      thickness="8px"
      fontSize="initial"
      {...props}
    />
  )
}

// INFO: based on Chakra UI spin animation (from https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/src/progress/progress.utils.tsx#L9)
const progressAnimation = keyframes({
  '0%': {
    strokeDasharray: '400, 400',
    strokeDashoffset: '400',
  },
  '100%': {
    strokeDasharray: '400, 400',
    strokeDashoffset: '0',
  },
})

type PositioningWrapperProps = BoxProps & {
  withProgressAnimation?: boolean
  delayAfterSuccessfulScan?: number
}

export const PositioningWrapper = styled(
  ({
    withProgressAnimation,
    delayAfterSuccessfulScan,
    ...props
  }: PositioningWrapperProps) => <Box {...props} />,
)(({ withProgressAnimation, delayAfterSuccessfulScan }) => {
  const { qrBoxDistanceFromTop } = getQrBoxDistanceFromTop()

  const offsetTop = qrBoxDistanceFromTop + PROGRESS_ELEMENT_MARGIN_TOP_RAW

  return {
    width: `${PROGRESS_ELEMENT_SIZE_RAW}px`,
    height: `${PROGRESS_ELEMENT_SIZE_RAW}px`,
    position: 'absolute',
    top: `${offsetTop}px`,
    left: `calc(50% - ${PROGRESS_ELEMENT_SIZE_RAW / 2}px)`,
    transform: 'rotate(-90deg)',

    '.chakra-progress__track': {
      stroke: 'transparent',
    },

    '.chakra-progress__indicator': withProgressAnimation &&
      delayAfterSuccessfulScan && {
        animation: `${progressAnimation} ${delayAfterSuccessfulScan}ms linear infinite`,
      },
  }
})
