import { useQuery } from '@tanstack/react-query'

import { apiWithoutCamelised } from 'apiServices'
import type { ModelGetShoppingCartData } from 'libs/openapiGeneratedApi/Api'

import type { MODEL__MonetaryAmount } from 'app/models/monetaryAmount'

import type { MODEL__RequiredApproval } from '../../ApprovalFlows/models'
import {
  currentCartScope,
  cartEssentialDataRelatedQuerySettings,
} from '../consts'
import type {
  MODEL__CartAccountType,
  MODEL__CartItem,
  MODEL__CartItemBatch,
} from '../models'

export type API__CartResponse = {
  account: MODEL__CartAccountType | null
  generalLedgerId: string | null
  items: MODEL__CartItemBatch[]
  total: MODEL__MonetaryAmount
  unavailableArticles?: MODEL__CartItem[]
  requiredApprovals?: MODEL__RequiredApproval[]
  customOrderNumberSequenceExhausted: boolean
  accountTypeAndValueMandatory: boolean
  generalLedgerMandatory: boolean
  generalLedgerCartLevelEnabled: boolean
  additionalAccountingInfo: string | null
  orderComment: string | null
  selectiveCheckoutEnabled?: boolean
  itemsCount: number
  selectedItemsCount: number
} & Pick<ModelGetShoppingCartData, 'cartRestriction'>

export const url = currentCartScope

const getCartRequest = () =>
  apiWithoutCamelised.get<unknown, API__CartResponse>(url)

export const CART_QUERY_KEY = 'cart'

export const useGetCartQuery = () =>
  useQuery({
    queryKey: [CART_QUERY_KEY],
    queryFn: getCartRequest,
    ...cartEssentialDataRelatedQuerySettings,
  })
