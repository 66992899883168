import { Box, Flex } from '@chakra-ui/react'
import type {
  ClearIndicatorProps,
  ControlProps,
  GroupBase,
  MenuListProps,
  NoticeProps,
  OptionProps,
  SingleValueProps,
} from 'chakra-react-select'
import { chakraComponents } from 'chakra-react-select'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'theme'
import { translations } from 'locales/translations'

import { TickIcon, XIcon } from '../../icons'

import type { MyOption } from './types'

const noOptionsMessage = translations.formFields.genericSelectNoOptionsMessage

type CustomComponentsProps = {
  leftIcon?: JSX.Element
  isIconOnlyValue?: boolean
}

export const useCustomComponentsFactory = () => {
  const { t } = useTranslation()
  const {
    colors: { contentB },
  } = useTheme()

  return useCallback(
    (options?: CustomComponentsProps) => {
      const { leftIcon, isIconOnlyValue } = options ?? {}

      return {
        Control: ({
          children,
          ...props
        }: ControlProps<MyOption, false, GroupBase<MyOption>>) => (
          <chakraComponents.Control {...props}>
            {!isIconOnlyValue && leftIcon && (
              <Box ml="12px" className="left-icon">
                {leftIcon}
              </Box>
            )}

            {children}
          </chakraComponents.Control>
        ),
        Option: ({
          children,
          ...props
        }: OptionProps<MyOption, false, GroupBase<MyOption>>) => {
          const { isSelected } = props
          const { id, icon, description } = props.data

          return (
            <chakraComponents.Option {...props}>
              <Flex
                gap="16px"
                width="100%"
                justify="space-between"
                data-testid={id}
              >
                <Flex>
                  {icon && <span style={{ marginRight: '12px' }}>{icon}</span>}
                  {children}
                  {description && (
                    <span style={{ color: contentB }}>&nbsp;{description}</span>
                  )}
                </Flex>
                {isSelected && (
                  <TickIcon style={{ alignSelf: 'center', flex: '0 0 20px' }} />
                )}
              </Flex>
            </chakraComponents.Option>
          )
        },
        SingleValue: ({
          children,
          ...props
        }: SingleValueProps<MyOption, false, GroupBase<MyOption>>) => (
          <chakraComponents.SingleValue {...props}>
            {isIconOnlyValue ? <Box width="30px">{leftIcon}</Box> : children}
          </chakraComponents.SingleValue>
        ),
        ClearIndicator: ({
          children: _children,
          ...props
        }: ClearIndicatorProps<MyOption, false, GroupBase<MyOption>>) => (
          <chakraComponents.ClearIndicator {...props}>
            <XIcon />
          </chakraComponents.ClearIndicator>
        ),
        NoOptionsMessage: (
          props: NoticeProps<MyOption, false, GroupBase<MyOption>>,
        ) => (
          <chakraComponents.NoOptionsMessage {...props}>
            {t(noOptionsMessage)}
          </chakraComponents.NoOptionsMessage>
        ),
        MenuList: ({
          children,
          ...props
        }: MenuListProps<MyOption, false, GroupBase<MyOption>>) => (
          <chakraComponents.MenuList {...props}>
            {/*Workaround with translate="no" for https://simplesystem.atlassian.net/browse/S2NG-11847*/}
            <Flex translate="no" direction="column">
              {children}
            </Flex>
          </chakraComponents.MenuList>
        ),
      }
    },
    [contentB, t],
  )
}
