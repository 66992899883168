import type { StackProps } from '@chakra-ui/react'
import { Box, HStack } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import { SpinnerIcon } from '../icons'

export const SpinnerWrapper = styled(Box)(
  () =>
    ({
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0,0,0,0.05)',
    }) as const,
)

export const BlockSpinnerWrapper = (props: StackProps) => (
  <HStack
    width="100%"
    height="100%"
    minHeight="120px"
    justifyContent="center"
    {...props}
  />
)

const spinAnimation = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
    animationTimingFunction: 'linear',
  },
  '100%': {
    transform: 'rotate(-360deg)',
    animationTimingFunction: 'linear',
  },
})

export const CustomSpinner = styled(SpinnerIcon)(({ theme: { zIndexes } }) => ({
  zIndex: zIndexes.basic,
  animation: `${spinAnimation} 1.5s linear infinite`,
}))
