import { Modal, ModalBody, Stack, ModalContent } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'

import { useCodeScan } from './useCodeScan'
import { Header, ProgressButton, ScanningArea } from './components'
import { useResetZoom } from './useResetZoom'
import type { MobileScannerProps } from './types'

export const MobileScannerComponent = ({
  children,
  onScanSuccessHandler,
  onScannerCloseHandler,
  hooksToCallWithCodeScanActions,
  shouldShowProgressButton,
  isScanPending,
  isScannedValueProcessedSuccessfully,
  delayAfterSuccessfulScan,
  header,
  scannerReadyMessage,
  scannedSuccessfullyMessage,
  modalProps,
}: MobileScannerProps) => {
  const codeScanActions = useCodeScan({ onScanSuccessHandler })
  const {
    scannerState,
    isScannerInitialized,
    startScanning,
    resumeScanning,
    videoPermission,
    isVideoPermissionLoading,
    isProgressButtonShown,
    setIsProgressButtonShown,
  } = codeScanActions

  const { setViewportMetaTagToResetAndDisableZoom, handleMobileScannerClose } =
    useResetZoom({ onScannerCloseHandler })

  const progressButtonRendered = (
    <ProgressButton
      resumeScanning={resumeScanning}
      isScanPending={isScanPending}
      isProgressButtonShown={isProgressButtonShown}
      setIsProgressButtonShown={setIsProgressButtonShown}
      delayAfterSuccessfulScan={delayAfterSuccessfulScan}
    />
  )

  if (hooksToCallWithCodeScanActions) {
    hooksToCallWithCodeScanActions(codeScanActions)
  }

  return (
    <>
      {/* INFO: manipulations inside onChangeClientState allows to override existing meta tags with new content instead of creating duplicated tags
      thanks to https://github.com/nfl/react-helmet/issues/341#issuecomment-1637155385 */}
      <Helmet onChangeClientState={setViewportMetaTagToResetAndDisableZoom} />

      <Modal
        isOpen
        size="full"
        allowPinchZoom // INFO: enables zooming in/out inside modal on iOS devices
        scrollBehavior="inside"
        onClose={handleMobileScannerClose}
        {...modalProps}
      >
        <ModalContent>
          <Header
            header={header}
            isScannerInitialized={isScannerInitialized}
            videoPermission={videoPermission}
            isVideoPermissionLoading={isVideoPermissionLoading}
            handleMobileScannerClose={handleMobileScannerClose}
          />

          <ModalBody as={Stack} spacing="20px" padding={0}>
            <ScanningArea
              scannerState={scannerState}
              startScanning={startScanning}
              videoPermission={videoPermission}
              isScannedValueProcessedSuccessfully={
                isScannedValueProcessedSuccessfully
              }
              scannerReadyMessage={scannerReadyMessage}
              scannedSuccessfullyMessage={scannedSuccessfullyMessage}
              {...(shouldShowProgressButton && {
                progressButton: progressButtonRendered,
              })}
            />

            {children ? children(codeScanActions) : null}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
